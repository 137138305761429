import { useLazyQuery } from '@apollo/client';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import { FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	ScrollView,
	StyleSheet,
	View,
	Text,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Dropdown } from '../..';
import Dropdown2 from '../../common/dropdown2/dropdown2';
import { useGetAllBrands } from '../../../api/brands/useGetAllBrands';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import useCreateProduct from '../../../api/product/useCreateProduct';
import useEditProduct from '../../../api/product/useEditProduct';
import useGetAllProducts from '../../../api/product/useGetAllProducts';
import { PRODUCT_CATEGORIES } from '../../../constants/categories';
import { GET_PRODUCT } from '../../../graphql/product/queries';
import { setRefetchProduct } from '../../../redux/productsSlice';
import { TGetAllBrandsData } from '../../../types/brandTypes';
import {
	Media,
	TCreateProduct,
	TDropdownObject,
	TUseNavigation,
} from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Button from '../../common/button/Button';
import { HorizontalDivider } from '../../common/horizontal-divider';
import Input from '../../common/Input';
import Label from '../../common/label/Label';
import OptionButton from '../../common/option-button/OptionButton';
import Spacing from '../../common/spacing/spacing';
import Upload from '../../common/upload/upload';
import BooleanButton from './BooleanButton';
import {
	addSingleProductInitialValues,
	addSingleProductValidationSchema,
	emptyVariant,
	handleEditProducts,
	TStatus,
	emptyVariant as addVariantInitialValues,
	emptyValue,
} from './FormikConstants';
import { UploadedProductImage } from './UploadedProductImage';
import { Variant } from '../../../types/productTypes';
import { CategoryPill } from '../../common/filter-categories/CategoryPill';
import { TAddVariantFormValues } from './VariantFormConstants';
import useGetMedia from '../../../api/common/useGetMedia';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { palettes } from '../../../config';
import { useAppSelector } from '../../../redux/store';

type Props = {
	productId?: string;
};
const AddSingleProductForm = (props: Props) => {
	const [productStatus, setProductStatus] = useState('ACTIVE');
	const [variantType, setVariantType] = useState<any[]>([]);
	const [variantValues, setVariantValues] = useState<any>({});
	const { INTEREST_TAGS } = useAppSelector(state => state.tag);
	const { setAlert } = useSetAlert();
	const [currentVariant, setCurrentVariant] = useState(0);
	const [media, setMedia] = useState<Media[]>([]);
	const {
		_createMedia,
		loading: loading_uploadMedia,
		data: mediaData,
	} = useCreateMedia({});

	const [getSingleProduct, { data: product, loading: productLoading }] =
		useLazyQuery(GET_PRODUCT, {
			onCompleted: data => {
				setProductStatus(data?.getProduct?.data?.publishedStatus);
			},
		});
	useEffect(() => {
		if (props.productId)
			getSingleProduct({ variables: { _id: props.productId } });
	}, []);

	const productData = product?.getProduct?.data;

	const dispatch = useDispatch();

	const {
		executeProductMutation,
		data,
		loading: productCreateLoading,
	} = useCreateProduct();
	const {
		executeProductEditMutation,
		data: productEdit,
		loading: productEditLoading,
	} = useEditProduct();

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const navigation = useNavigation<TUseNavigation>();
	const { getAllBrands, data: brandApiData } = useGetAllBrands();
	useEffect(() => {
		const media = mediaData?.createMedia?.data;

		if (media) {
			setMedia(prev => [...prev, media]);
		}
	}, [mediaData]);

	useEffect(() => {
		getAllBrands();
	}, []);

	const brandTestData = [
		{
			_id: '',
			companyName: 'Select Brand'
		}
	]

	const brandData = () => {
		if (loggedInUserDetails?.affiliatedBrand) {
			return brandTestData?.concat(brandApiData?.filter((data: any) => data?._id === loggedInUserDetails?.affiliatedBrand))
		} else {
			return brandApiData
		}
	}

	const variantTypes = [
		{
			_id: '',
			variantName: 'Select Variant'
		},
		{
			_id: 'Size',
			variantName: 'Size'
		},
		{
			_id: 'Color',
			variantName: 'Color'
		},
		{
			_id: 'Material',
			variantName: 'Material'
		},
		{
			_id: 'Type',
			variantName: 'Type'
		},
		{
			_id: 'Volume',
			variantName: 'Volume'
		},
		{
			_id: 'Weight',
			variantName: 'Weight'
		},
		{
			_id: 'Dimension',
			variantName: 'Dimension'
		},
	]
	//TODO for deleting variants
	// const handleRemove=()=>{

	// }

	useEffect(() => {
		if (productEdit) {
			navigation.navigate('Products');
			dispatch(setRefetchProduct(true));
		}
	}, [productEdit]);

	useEffect(() => {
		if (productData?._id) {
			const [firstVariator, secondVariator] = productData?.variants?.[0]?.option?.variatorValues
			if (firstVariator) {
				setVariantValues({ ...firstVariator?.variatorValues, ...secondVariator?.variatorValues })
				const firstVariantType = Object.values(productData?.variants?.[0]?.option?.variatorValues?.[0]?.variatorValues || {})?.map((data: any, index: number) => {
					return {
						type: firstVariator?.variator,
						indexPlace: index
					}
				})
				const secondVariantType = Object.values(productData?.variants?.[0]?.option?.variatorValues?.[1]?.variatorValues || {})?.map((data: any, index: number) => {
					return {
						type: secondVariator?.variator,
						indexPlace: index
					}
				})

				setVariantType([...firstVariantType, ...secondVariantType])
			}
		}
	}, [productData])

	const getFromForVariant = (variant: string) => {
		return variantType?.filter(data => variant === data?.type)
	}


	const dynamicSetInputValues = (field: string, text: string) => {
		setVariantValues({
			...variantValues,
			[field]: {
				text,
				quantity: variantValues[field]?.quantity,
				associatedVariant: variantValues[field]?.associatedVariant,
				price: variantValues[field]?.price,
				comparePrice: variantValues[field]?.comparePrice,
			},
		})
	}

	const dynamicGroupQuantityWithValue = (field: string, quantity: string) => {
		setVariantValues({
			...variantValues,
			[field]: {
				text: variantValues[field]?.text,
				quantity: +quantity,
				associatedVariant: variantValues[field]?.associatedVariant,
				price: variantValues[field]?.price,
				comparePrice: variantValues[field]?.comparePrice,
			},
		})
	}

	const dynamicGroupPriceWithValue = (field: string, price: string) => {
		setVariantValues({
			...variantValues,
			[field]: {
				text: variantValues[field]?.text,
				quantity: variantValues[field]?.quantity,
				associatedVariant: variantValues[field]?.associatedVariant,
				comparePrice: variantValues[field]?.comparePrice,
				price,
			},
		})
	}

	const dynamicGroupComparePriceWithValue = (field: string, price: string) => {
		setVariantValues({
			...variantValues,
			[field]: {
				text: variantValues[field]?.text,
				quantity: variantValues[field]?.quantity,
				associatedVariant: variantValues[field]?.associatedVariant,
				price: variantValues[field]?.price,
				comparePrice: price,
			},
		})
	}

	const dynamicGroupVariantWithAssociate = (field: string, text: string) => {
		setVariantValues({
			...variantValues,
			[field]: {
				text: variantValues[field]?.text,
				quantity: variantValues[field]?.quantity,
				associatedVariant: text,
				price: variantValues[field]?.price,
				comparePrice: variantValues[field]?.comparePrice,
			},
		})
	}


	const getInputValue = (variator: string, field: string) => {
		return variantValues[variator]?.[field]
	}

	const getVariatorSecondVariantValues = (variant: string) => {
		const secondVariantValues = Object.values(extractFields(variantValues, variant) || {})?.map((data: any) => {
			return {
				key: data?.text,
				value: data?.text,
			}
		})
		return [{ key: "Select", value: null }, ...secondVariantValues]
	}

	// console.log({ variantValuesSelect: extractFields(variantValues, 'Size'), variantValues: getFromForVariant('Size'), variantType });

	function extractFields(obj: any, field: string) {
		const result = {} as any;
		for (const key in obj) {
			if (obj.hasOwnProperty(key) && key.startsWith(field)) {
				result[key] = obj[key];
			}
		}
		return result;
	}


	return (
		<Formik
			enableReinitialize
			initialValues={
				props.productId
					? handleEditProducts(productData)
					: addSingleProductInitialValues
			}
			validationSchema={addSingleProductValidationSchema}
			onSubmit={async (values, { resetForm }) => {
				const data = {
					name: values.productName,
					shortDescription: values.shortDescription,
					description: values.description,
					publishedStatus: productStatus,
					category: values.productCategory,
					brand: values.brand,
					variants: [{
						// variator: values?.variants[0].variantType,
						value: values.value,
						GTIN: values?.GTIN,
						image: values?.image?._id || values?.variants?.[0]?.image?._id,
						_id:
							values?._id && values?._id.length
								? values?._id
								: undefined,
						vat: +values?.vat,
						weight: Number(values?.weight),
						weightUnit: values.weightUnit,
						option: {
							values: [
								{
									price: +extractFields(variantValues, values?.variants[0]?.variantType)?.[`${values?.variants[0]?.variantType}1`]?.price,
									name: values?.variants[0]?.variantType,
								}
							],
							variatorValues: values?.variants?.map((data) => {
								return {
									variator: data?.variantType,
									variatorValues: extractFields(variantValues, data?.variantType)
								}
							}),

							inventory: {
								isAvailable: true,
								isTracked: true,
								quantity: Number(
									Object.values(extractFields(variantValues, values?.variants[0]?.variantType))?.map((data: any) => data?.quantity)?.reduce((a: any, b: any) => +a + +b, 0)
								),
							},
						},
					}
					]
					// variants: values?.variants?.map(variant => {
					// 	return {
					// 		variator: variant.variantType,
					// 		value: variant.value,
					// 		GTIN: variant?.GTIN,
					// 		image: variant?.image?._id?.length
					// 			? variant?.image?._id
					// 			: undefined,
					// 		_id:
					// 			variant?._id && variant?._id.length
					// 				? variant?._id
					// 				: undefined,
					// 		vat: +variant?.vat,
					// 		weight: Number(variant?.weight),
					// 		weightUnit: variant.weightUnit,
					// 		option: {
					// 			name: variant?.options?.name,
					// 			values: variant?.options?.values?.map(
					// 				(item, index) => {
					// 					return {
					// 						price: +item.price,
					// 						compareAtPrice:
					// 							Number(item.compareAtPrice) ??
					// 							undefined,
					// 						name: item.name,
					// 					};
					// 				}
					// 			),

					// 			inventory: {
					// 				isAvailable:
					// 					variant?.options?.inventory.isAvailable,
					// 				isTracked:
					// 					variant?.options?.inventory?.isTracked,
					// 				quantity: Number(
					// 					variant?.options?.inventory.quantity
					// 				),
					// 			},
					// 		},
					// 	};
					// }),
				};

				console.log(data, values, 'form values');
				if (props.productId) {
					executeProductEditMutation(
						props.productId as string,
						data as unknown as TCreateProduct
					);
					return;
				}
				const response = await executeProductMutation(
					data as unknown as TCreateProduct
				);
				if (response.data?.createProduct?.success) {
					resetForm();
					setMedia([]);
					setVariantValues({})
					setVariantType([])
					setCurrentVariant(0)
				}
			}}
		>
			{({
				handleChange,
				handleBlur,
				setFieldValue,
				handleSubmit,
				dirty,
				isValid,
				touched,
				errors,
				values,
			}) => (
				<>
					<Spacing
						orientation="column"
						spacing={20}
						containerStyles={{
							marginBottom: getResponsiveStyle(80),
						}}
					>
						<Input
							label="Product Name*"
							onChangeText={handleChange('productName')}
							onBlur={handleBlur('productName')}
							value={values.productName}
							error={
								touched.productName && errors.productName
									? errors.productName
									: ''
							}
						/>

						<Input
							multiline
							label={'Short Description*'}
							style={[styles.shortDescription]}
							onChangeText={handleChange('shortDescription')}
							onBlur={handleBlur('shortDescription')}
							value={values.shortDescription}
							error={
								touched.shortDescription &&
									errors.shortDescription
									? errors.shortDescription
									: ''
							}
						/>
						<Input
							multiline
							label={'Description*'}
							style={[styles.description]}
							value={values.description}
							onChangeText={handleChange('description')}
							onBlur={handleBlur('description')}
							error={
								touched.description && errors.description
									? errors.description
									: ''
							}
						/>

						<Dropdown
							labelProps={{
								label: 'Brand:',
							}}
							pickerProps={{
								selectedValue: values.brand,
								onValueChange: value => {
									setFieldValue('brand', value);
								},
								children: (
									<>
										{brandData()?.map((brand: any) => (
											<Picker.Item
												label={brand?.companyName}
												value={brand?._id}
											/>
										))}
									</>
								),
							}}
						/>
						<HorizontalDivider paddingY={getResponsiveStyle(24)} />
						<Dropdown2
							isSearchable
							isMulti
							key={'dropdown'}
							options={INTEREST_TAGS}
							inputProps={{
								placeholder: 'Product Categories hint',
							}}
							labelProps={{
								label: 'Product Categories*',
							}}
							values={INTEREST_TAGS.filter(item =>
								values.productCategory?.includes(item.value)
							)}
							onChangeValue={(value: TDropdownObject) => {
								setFieldValue('productCategory', [
									...values?.productCategory,
									value?.value,
								]);
							}}
							onPressCrossOnBadge={(value: string) => {
								const filteredProductCategory =
									values?.productCategory?.filter(
										item => item !== value
									);

								setFieldValue(
									'productCategory',
									filteredProductCategory
								);
							}}
						/>
						<Spacing spacing={14} withEqualWidth>
							<OptionButton
								onClick={() => {
									setProductStatus(TStatus.DRAFT);
									//setFieldValue('status', TStatus.DRAFT);
								}}
								value={TStatus.DRAFT}
								active={productStatus === 'DRAFT'}
								title={'Draft'}
								titleStyles={{
									textAlign: 'center',
								}}
							/>
							<OptionButton
								onClick={() => {
									setProductStatus(TStatus.ACITVE);
									//setFieldValue('status', TStatus.ACITVE);
								}}
								value={TStatus.ACITVE}
								active={productStatus === 'ACTIVE'}
								title={'Active'}
								titleStyles={{
									textAlign: 'center',
								}}
							/>
						</Spacing>

						<HorizontalDivider paddingY={getResponsiveStyle(24)} />
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<ScrollView
								horizontal
								showsHorizontalScrollIndicator={false}
								style={{ marginRight: 20 }}
							>
								{values?.variants?.length ? (
									values?.variants?.map((item, index) => (
										<CategoryPill
											value={item?.value}
											selected={index === currentVariant}
											text={`Variant ${values?.variants?.[index]
												?.variantType?.length
												? values?.variants?.[index]
													?.variantType +
												' ' + values?.variants?.[index]
													?.value
												: index + 1
												}`}
											onClick={() => {
												setCurrentVariant(index);
												console.log(values.variants);
											}}
										/>
									))
								) : (
									<Text style={{ color: 'red' }}>
										At least one variant is required*
									</Text>
								)}
							</ScrollView>
							{values?.variants?.length === 2 ? null : <Button
								title="Add Variant"
								onPress={() => {
									setFieldValue('variants', [
										...values.variants,
										emptyVariant,
									]);
									setCurrentVariant(values.variants.length);
								}}
							/>}
						</View>
						<>
							{values?.variants?.map((item, index) => (
								<FieldArray
									key={index}
									name="variant"
									render={_arrayHelpers =>
										index === currentVariant && (
											<>
												<>
													<Spacing
														key={index}
														orientation="column"
														spacing={20}
													>
														<Dropdown
															labelProps={{
																label: 'Variant Type*',
															}}
															pickerProps={{
																selectedValue: item.variantType,
																onValueChange: value => {
																	setFieldValue(
																		'variants.' +
																		[
																			index,
																		] +
																		'.variantType',
																		value
																	);
																},
																children: (
																	<>
																		{variantTypes?.map((brand: any) => (
																			<Picker.Item
																				label={brand?.variantName}
																				value={brand?._id}
																			/>
																		))}
																	</>
																),
															}}
														/>
														<ScrollView
															horizontal
															showsHorizontalScrollIndicator={false}
														>
															<View>
																<View style={{ flexDirection: "row" }}>
																	{getFromForVariant(item?.variantType)?.map((_, index) => <Input
																		containerStyles={{ width: 130 }}
																		label={`${item.variantType} ${index + 1}`}
																		onChangeText={(text) => dynamicSetInputValues(`${item.variantType}${index + 1}`, text)}
																		// onBlur={handleBlur('productName')}
																		value={getInputValue(`${item.variantType}${index + 1}`, 'text')}
																	/>)}
																</View>
																<Button
																	title="Add Variant Type"
																	onPress={() => {
																		if (item.variantType) setVariantType([...variantType, { type: item.variantType, indexPlace: variantType.length }])
																	}}
																/>
																{currentVariant === 0 && (values?.variants?.length > 1) ?
																	<View style={{ flexDirection: "row" }}>
																		{getFromForVariant(item?.variantType)?.map((_, index) => (
																			<>
																				{/* <Input
																					containerStyles={{ width: 100 }}
																					label={`Associated Variant ${values?.variants[1]?.variantType} ${index + 1}`}
																					onChangeText={(text) => dynamicGroupVariantWithAssociate(`${item.variantType}${index + 1}`, text)}
																					// onBlur={handleBlur('productName')}
																					value={getInputValue(`${item.variantType}${index + 1}`, 'associatedVariant')}
																				// error={
																				// 	touched.productName && errors.productName
																				// 		? errors.productName
																				// 		: ''
																				// }
																				/> */}
																				<View style={{ width: 130 }}>
																					<Dropdown
																						labelProps={{
																							label: `Associated Variant ${values?.variants[1]?.variantType} ${index + 1}`,
																						}}

																						pickerProps={{
																							selectedValue: getInputValue(`${item.variantType}${index + 1}`, 'associatedVariant'),
																							onValueChange: value => {
																								// setFieldValue('brand', value);

																								dynamicGroupVariantWithAssociate(`${item.variantType}${index + 1}`, value as string)
																							},
																							children: (
																								<>
																									{getVariatorSecondVariantValues(values?.variants[1]?.variantType)?.map((data: any) => (
																										<Picker.Item
																											label={data?.key}
																											value={data?.value}
																										/>
																									))}
																								</>
																							),
																						}}
																					/>
																				</View>
																			</>
																		))}
																	</View> : <></>
																}
																{currentVariant === 0 ?
																	<View style={{ flexDirection: "row" }}>
																		{getFromForVariant(item?.variantType)?.map((_, index) => <Input
																			containerStyles={{ width: 130 }}
																			label={`Quantity ${index + 1}`}
																			onChangeText={(text) => dynamicGroupQuantityWithValue(`${item.variantType}${index + 1}`, text)}																	// onBlur={handleBlur('productName')}
																			value={getInputValue(`${item.variantType}${index + 1}`, 'quantity')}
																		// error={
																		// 	touched.productName && errors.productName
																		// 		? errors.productName
																		// 		: ''
																		// }
																		/>)}
																	</View> : <></>
																}
																{currentVariant === 0 ?
																	<View style={{ flexDirection: "row" }}>
																		{getFromForVariant(item?.variantType)?.map((_, index) => <Input
																			containerStyles={{ width: 130 }}
																			label={`Price ${index + 1}`}
																			onChangeText={(text) => dynamicGroupPriceWithValue(`${item.variantType}${index + 1}`, text)}																	// onBlur={handleBlur('productName')}
																			value={getInputValue(`${item.variantType}${index + 1}`, 'price')}
																		// error={
																		// 	touched.productName && errors.productName
																		// 		? errors.productName
																		// 		: ''
																		// }
																		/>)}
																	</View> : <></>
																}
																{currentVariant === 0 ?
																	<View style={{ flexDirection: "row" }}>
																		{getFromForVariant(item?.variantType)?.map((_, index) => <Input
																			containerStyles={{ width: 130 }}
																			label={`Compare Price ${index + 1}`}
																			onChangeText={(text) => dynamicGroupComparePriceWithValue(`${item.variantType}${index + 1}`, text)}																	// onBlur={handleBlur('productName')}
																			value={getInputValue(`${item.variantType}${index + 1}`, 'comparePrice')}
																		// error={
																		// 	touched.productName && errors.productName
																		// 		? errors.productName
																		// 		: ''
																		// }
																		/>)}
																	</View> : <></>
																}
															</View>
														</ScrollView>
														{/* <Input
															label="Variant Type*"
															onChangeText={text => {
																setFieldValue(
																	'variants.' +
																	[
																		index,
																	] +
																	'.variantType',
																	text
																);
															}}
															onBlur={handleBlur(
																'variants.' +
																[index] +
																'.variantType'
															)}
															value={
																item.variantType
															}
															error={
																touched.variants &&
																	touched
																		.variants[
																	index
																	] &&
																	errors.variants
																	? errors
																		.variants[
																		index
																	]
																		?.variantType
																	: ''
															}
														/> */}
														{/* <Input
															label="Variant Value *"
															onChangeText={text => {
																setFieldValue(
																	'variants.' +
																	[
																		index,
																	] +
																	'.value',
																	text
																);
															}}
															onBlur={handleBlur(
																'variants.' +
																[index] +
																'.value'
															)}
															value={item.value}
															error={
																touched.variants &&
																	touched
																		.variants[
																	index
																	] &&
																	errors.variants
																	? errors
																		.variants[
																		index
																	]?.value
																	: ''
															}
														/> */}
													</Spacing>
												</>
											</>
										)
									}
								/>
							))}
						</>
						<>
							<Input
								label="Product ID/GTIN *"
								onChangeText={text => {
									setFieldValue('GTIN', text);
								}}
								onBlur={handleBlur("GTIN")}
								value={values.GTIN}
								error={
									touched?.GTIN && errors?.GTIN ? errors?.GTIN : ''
								}
							/>
							<View>
								<Upload
									label="Product Image*"
									hint="Drop files to upload"
									onDrop={e =>
										_createMedia(
											{
												variables:
												{
													file: e[0] as File,
													category:
														'PRODUCT',
													alt: 'media-files',
												},
												onCompleted:
													data => {
														setAlert(
															'Upload Successful',
															'normal'
														);
														console.log(
															data
																.createMedia
																.data
														);
														setFieldValue(
															'image',
															{
																_id: data
																	?.createMedia
																	?.data
																	?._id,
																src: data
																	?.createMedia
																	?.data
																	?.src,
															}
														);
													},
											}
										)
									}
									dropzoneStyles={{
										height: getResponsiveStyle(
											240,
											'dimensions'
										),
									}}
									containerStyles={{
										marginBottom:
											getResponsiveStyle(
												24
											),
									}}
								/>
								{/* {console.log({ imageCheck: values?.variant })} */}
								{loading_uploadMedia ? (
									<ActivityIndicator
										size="small"
										style={{
											margin: 20,
										}}
									/>
								) : (
									(values?.image?._id || !!values
										.variants?.[
										0
									]?.image?._id
										?.length) && (
										<Spacing>
											<UploadedProductImage
												image={
													values?.image?.src || values
														.variants[0]
														.image
														.src
												}
											/>
											<></>
										</Spacing>
									)
								)}
							</View>
							<Spacing
								spacing={8}
								withEqualWidth
							>
								<Input
									label="VAT (%)*"
									value={values.vat}
									onChangeText={text => {
										setFieldValue(
											'vat',
											text
										);
									}}
									onBlur={handleBlur(
										'vat'
									)}
									error={
										touched?.vat && errors?.vat ? errors?.vat : ''
									}
								/>
								<View></View>
							</Spacing>

							{/* <Input
								label="Option Name"
							// onChangeText={text => {
							// 	setFieldValue(
							// 		'variants.' +
							// 		[
							// 			index,
							// 		] +
							// 		'.options.name',
							// 		text
							// 	);
							// }}
							// onBlur={handleBlur(
							// 	'variants.' +
							// 	[index] +
							// 	'.options.name'
							// )}
							// value={
							// 	values.variants[
							// 		index
							// 	].options
							// 		.name ?? ''
							// }
							// error={
							// 	touched.variants &&
							// 		touched
							// 			.variants[
							// 		index
							// 		] &&
							// 		errors.variants
							// 		? errors
							// 			.variants[
							// 			index
							// 		]?.options
							// 			?.name
							// 		: ''
							// }
							/> */}
							{/* <Text
								style={{
									color: 'red',
									paddingVertical:
										'20px',
								}}
							>
								At least one Option is
								required*
							</Text>
							{item?.options?.values?.map(
								(item1, index1) => (
									<Spacing
										key={
											index1 +
											'nested'
										}
										spacing={8}
										withEqualWidth
									>
										<Input
											label="Option Value"
											onChangeText={text => {
												setFieldValue(
													'variants.' +
													[
														index,
													] +
													'.options.values.' +
													[
														index1,
													] +
													'.name',
													text
												);
											}}
											onBlur={handleBlur(
												'variants.' +
												[
													index,
												] +
												'.options.values.' +
												[
													index1,
												] +
												'.name'
											)}
											value={
												item1?.name
											}
											error={
												touched.variants &&
													touched
														.variants[
													index
													] &&
													errors.variants
													? errors
														.variants[
														index
													]
														?.options
														?.values[
														index1
													]
														?.name
													: ''
											}
										/>

										<Input
											label="Listed Price"
											// onChangeText={text => {
											// 	setFieldValue(
											// 		'variants.' +
											// 		[
											// 			index,
											// 		] +
											// 		'.options.values.' +
											// 		[
											// 			index1,
											// 		] +
											// 		'.price',
											// 		text
											// 	);
											// }}
											// onBlur={handleBlur(
											// 	'variants.' +
											// 	[
											// 		index,
											// 	] +
											// 	'.options.values.' +
											// 	[
											// 		index1,
											// 	] +
											// 	'.price'
											// )}
											// value={
											// 	(values.variants &&
											// 		values
											// 			.variants[
											// 		index
											// 		] &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values[
											// 		index1
											// 		] &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values[
											// 			index1
											// 		]
											// 			.price) ||
											// 	''
											// }
											// error={
											// 	touched.variants &&
											// 		touched
											// 			.variants[
											// 		index
											// 		] &&
											// 		errors.variants
											// 		? errors
											// 			.variants[
											// 			index
											// 		]
											// 			?.options
											// 			?.values[
											// 			index1
											// 		]
											// 			?.price
											// 		: ''
											// }
										/>

										<Input
											label="Compare Price"
											// onChangeText={text => {
											// 	setFieldValue(
											// 		'variants.' +
											// 		[
											// 			index,
											// 		] +
											// 		'.options.values.' +
											// 		[
											// 			index1,
											// 		] +
											// 		'.compareAtPrice',
											// 		text
											// 	);
											// }}
											// onBlur={handleBlur(
											// 	'variants.' +
											// 	[
											// 		index,
											// 	] +
											// 	'.options.values.' +
											// 	[
											// 		index1,
											// 	] +
											// 	'.compareAtPrice'
											// )}
											// value={
											// 	(values.variants &&
											// 		values
											// 			.variants[
											// 		index
											// 		] &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values[
											// 		index1
											// 		] &&
											// 		values
											// 			.variants[
											// 			index
											// 		]
											// 			.options
											// 			.values[
											// 			index1
											// 		]
											// 			.compareAtPrice) ||
											// 	''
											// }
											// error={
											// 	touched.variants &&
											// 		touched
											// 			.variants[
											// 		index
											// 		] &&
											// 		errors.variants
											// 		? errors
											// 			.variants[
											// 			index
											// 		]
											// 			?.options
											// 			?.values[
											// 			index1
											// 		]
											// 			?.compareAtPrice
											// 		: ''
											// }
										/>
									</Spacing>
								)
							)} */}
							{/* <Button
								title="Add Option Value"
							// onPress={() => {
							// 	setFieldValue(
							// 		'variants.' +
							// 		[index] +
							// 		'.options.values',
							// 		[
							// 			...values
							// 				.variants[
							// 				index
							// 			].options
							// 				.values,
							// 			emptyValue,
							// 		]
							// 	);
							// }}
							/> */}
							{/* <HorizontalDivider
								paddingY={getResponsiveStyle(
									24
								)}
							/>
							<View
								style={
									styles.removeContainer
								}
							>
								<Input
									label="Stock quantity*"
									onChangeText={text => {
										setFieldValue(
											'variants.' +
											'.options.inventory.quantity',
											text
										);
									}}
								// onBlur={handleBlur(
								// 	'variants.' +
								// 	[index] +
								// 	'.options.inventory.quantity'
								// )}
								// value={
								// 	item.options
								// 		.inventory
								// 		.quantity
								// }
								// error={
								// 	touched?.variants &&
								// 		touched
								// 			.variants[
								// 		index
								// 		] &&
								// 		errors?.variants &&
								// 		errors.variants[
								// 		index
								// 		] &&
								// 		errors
								// 			?.variants[
								// 			index
								// 		]?.options &&
								// 		errors.variants[
								// 			index
								// 		]?.options
								// 			.errors
								// 			?.variants[
								// 			index
								// 		]?.options
								// 			.inventory
								// 		? errors
								// 			.variants[
								// 			index
								// 		]?.options
								// 			.errors
								// 			?.variants[
								// 			index
								// 		]?.options
								// 			.inventory
								// 			?.quantity
								// 		: ''
								// }
								/>
							</View> */}
							<View
								style={
									styles.removeContainer
								}
							>
								<Input
									label="Weight (kgs):"
									// onChangeText={text => {
									// 	setFieldValue(
									// 		'variants.' +
									// 		[
									// 			index,
									// 		] +
									// 		'.weight',
									// 		text
									// 	);
									// }}
									// onBlur={handleBlur(
									// 	'variants.' +
									// 	[index] +
									// 	'.weight'
									// )}
									value={
										values.weight ??
										''
									}
									onChangeText={text => {
										setFieldValue(
											'weight',
											text
										);
									}}
									onBlur={handleBlur(
										'weight'
									)}
									error={
										touched?.weight && errors?.weight ? errors?.weight : ''
									}
								/>
							</View>
						</>
						<></>
					</Spacing>
					<Button
						title={`${props.productId ? 'Update' : 'Save'}`}
						variant={isValid ? 'primary' : 'disabled'}
						size="lg"
						onPress={handleSubmit}
						loading={productCreateLoading || productEditLoading}
					/>
				</>
			)}
		</Formik>
	);
};

export default AddSingleProductForm;

const styles = StyleSheet.create({
	description: {
		paddingVertical: getResponsiveStyle(20),
		height: getResponsiveStyle(160, 'dimensions'),
	},
	removeContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	shortDescription: {
		paddingVertical: getResponsiveStyle(20),
		height: getResponsiveStyle(90, 'dimensions'),
	},
});
